<template>
    <section v-if="showSavedSearch" v-show="!getSavedSearches || getSavedSearches.length >0">
        <div class="grid fixed xs-grid-cols-1">
            <div class="text-center">
                <h2 class="f3 text-bold text-capitalize">
                    Your Saved Search In <a v-if="savedSearchInfo && savedSearchInfo.input" :href="glb.appUrl + savedSearchInfo.url" class="link">{{savedSearchInfo.input}}</a>
                </h2>
            </div>
            <mvtLazyload ref="lazyload" :async="true" data-role="propertycard">
                <mvtNearby v-if="savedSearchInfo && savedSearchInfo.url" type="filterPath" :filterPath="`${glb.appUrl}${savedSearchInfo.url}`" @completed="completed" carousalType="saved_search"></mvtNearby>
            </mvtLazyload>
        </div>
    </section>
</template>

<script src="./home-savedsearch.js"></script>
