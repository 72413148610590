<template>
    <div class="mvt-hotleadform grid xs-grid-cols-1 gap-3">
        <div v-if="profile" class="dialog-profile">
            <div v-if="options.profileHighlighter" class="highlighter"></div>
            <i v-if="/^icon-/.test(profile)" class="circle  toggle  huge" :class="profile" />
            <mvtMapStatic v-else-if="showStreetView" class="circle  toggle  huge" :cacheFirst="true" :lat="propertyData.geo.lat" :lng="propertyData.geo.lng" type="streetview" streetViewDefautType="satellite" :address="propertyData.geo.formatAddress"  width="70"  height="70"></mvtMapStatic>
            <img v-else :src="profile" class="circle  toggle  huge" />
        </div>
        <template v-if="showConfirmUI">
            <template v-if="isLenderApiLoading">
                <div class="flex middle center left text-bold f3">Loading...</div>
                <div  class="mvt-hotlead-confirm" >
                    <div class="loader-content">
                        <div class="loading"></div>
                        <div>One moment while we process your request.</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex middle center left f3"><i class="icon-send"></i> Sending message...</div>
                <div  class="mvt-hotlead-confirm" >
                    <div class="skeleton"></div>
                </div>
            </template>
        </template>

        <div class="grid xs-grid-cols-1 text-center">
            <h3 class="hotlead-title f3 text-bold" data-role="title" v-show="hotleadTitle" v-html="hotleadTitle"></h3>
            <div v-if="hotleadSub" v-html="hotleadSub"></div>
        </div>

        <template v-if="propertyData && options.showAddressAndImage">
            <div class="f6 text-center">
                <i class="icon-pin-location"></i> {{propertyData.geo.formatAddress}}
            </div>
            <div class="dpp-schedule-photo">
                <img loading="lazy" :title="propertyData.geo.formatAddress" :alt="propertyData.geo.formatAddress" :src="propertyData.tnImgPath" v-if="propertyData.photoCount > 0">
                <mvtMapStatic class="img" :cacheFirst="true" :lat="propertyData.geo.lat" :lng="propertyData.geo.lng" type="streetview" streetViewDefautType="satellite" :address="propertyData.geo.formatAddress" v-else></mvtMapStatic>
            </div>
        </template>

        <form
            class="form form-default grid xs-grid-cols-1 gap-3"
            :class="formState"
            v-submit="submit"
            novalidate
        >
            <div v-if="hasOJOAgent && ojoLeadFormConfig && ojoLeadFormConfig.showOJOagentInfo" class="assigned-agent-info text-center">
                <a class="link phone" :href="'tel:' + agentPhone">
                    <i class="icon-phone-o"></i>{{$filters.formatPhoneNumber(agentPhone)}}
                </a>
            </div>
            <mvtCalendar v-model="calendar"  v-if="options.enableChangeDate" :optionalDate="options.optionalDate"></mvtCalendar>
            <template v-if="hasOJOAgent">
                <div class="assigned-agent">
                    <div class="cta-section" v-if="ojoLeadFormConfig && ojoLeadFormConfig.showCtaLinks">
                        <div class="cta-sub">I'd like to..</div>
                        <div class="cta-links">
                            <a v-for="(item,i) in assignedAgentCtaLinks" :key="i"
                                @click="changeActiveCTA(i, item.value)"
                                :class="{active:activeCTAIndex == i}">
                                    {{item.text}}
                            </a>
                        </div>
                    </div>
                    <mvtTextbox class="message">
                        <div class="textarea">
                            <label>{{ojoLeadFormConfig && ojoLeadFormConfig.commnetLabel}}</label>
                            <textarea name="comment" v-model="hotlead.comment" maxlength="4000"></textarea>
                        </div>
                    </mvtTextbox>
                </div>

                <mvtTextbox v-if="getShowAddress">
                    <mvtInputGeo v-model="addressInput" :autoSelect="true" :isShowLabel="true" :hideButton="true" v-validate="'required|address'"  data-geotype="zipcode" :placeholder="'Address'"  :options="{types:['address']}"/>
                </mvtTextbox>
                <mvtTextbox v-else-if="showZipcode">
                    <mvtInputGeo v-model="addressInput" :autoSelect="true" :isShowLabel="true" :hideButton="true" v-validate="'required|geo'" data-geotype="zipcode" :placeholder="'ZIP Code'" :options="{types:['postal_code']}"/>
                </mvtTextbox>
                <mvtTextbox v-else-if="showInputGeo">
                    <mvtInputGeo v-model="addressInput" :autoSelect="true" :isShowLabel="true" :hideButton="true" v-validate="'required|geo'" data-geotype="zipcode" :placeholder="'Address or ZIP Code'"/>
                </mvtTextbox>

                <div class="form-input" v-if="!hasDefaultPhone">
                    <mvtTextbox>
                        <div class="input">
                            <label>{{ molang('search.common.MobilePhone') }}</label>
                            <input
                                ref="phone"
                                type="tel"
                                name="phone"
                                v-model="hotlead.phone"
                                v-inputformat:phone
                                :autocomplete="isACForm ? 'off': 'tel-national'"
                                v-validate="'required|phoneNumber'"
                                maxlength="12"
                                placeholder="123-123-1234"
                            />
                        </div>
                    </mvtTextbox>
                </div>
            </template>
            <template v-else>
                <div class="form-input" v-if="options.showInterestOptions" >
                    <p class="m-b-1">What are your real estate goals?</p>
                    <mvtSelect class="input" v-model="interestedIn" :data="interestOptions" />
                </div>
                <div class="form-input" >
                    <mvtSelect class="input" v-if="showEnquiryInput && !isACForm" ref="enquiryTypes" v-model="hotlead.enquiryType" :data="enquiryTypesData" v-validate="'required|selectOption'"/>
                    <mvtRadio v-if="isACForm" class="flex nowrap center" v-model="acFormLeadType" :data="acFormLeadCheckList"/>
                    <mvtTextbox  v-if="!glb.user.fullName || readFullName">
                        <div class="input">
                            <label>{{ molang('search.common.name') }}</label>
                            <input
                                ref="name"
                                type="text"
                                name="name"
                                v-inputtrim
                                v-model="hotlead.name"
                                :readonly="!!glb.user.fullName && !editFullName"
                                maxlength="128"
                                v-validate="'required|name'"
                                :autocomplete="isACForm ? 'off': 'name'"
                                placeholder="Full Name"
                            />
                        </div>
                    </mvtTextbox>
                    <mvtTextbox v-if="!glb.user.email || readEmail">
                        <div class="input">
                            <label>{{ molang('search.common.email') }}</label>
                            <input
                                type="email"
                                name="email"
                                v-model="hotlead.email"
                                :readonly="!!glb.user.email && !editEmail"
                                maxlength="128"
                                :autocomplete="isACForm ? 'off': 'email'"
                                v-inputtrim
                                v-validate="'required|email'"
                                placeholder="example@email.com"
                            />
                        </div>
                    </mvtTextbox>
                    <mvtTextbox v-if="!glb.user.phone || readPhone">
                        <div class="input">
                            <label>{{ molang('search.common.MobilePhone') }}</label>
                            <input
                                ref="phone"
                                type="tel"
                                name="phone"
                                v-model="hotlead.phone"
                                :readonly="!!glb.user.phone && !editPhone"
                                v-inputformat:phone
                                :autocomplete="isACForm ? 'off': 'tel-national'"
                                v-validate="'required|phoneNumber'"
                                maxlength="12"
                                placeholder="123-123-1234"
                            />
                        </div>
                    </mvtTextbox>
                    <mvtTextbox  v-if="options.propertyData && options.propertyData.isRentals">
                        <div class="input right">
                            <label>Move-In Date (Est)</label>
                            <mvtInputDate ref="inputDate" v-model="hotlead.moveIn" :min="minMoveInDate" v-validate="'required'" :disableWeekends="false" type="date" />
                        </div>
                    </mvtTextbox>
                    <mvtTextbox v-if="getShowAddress">
                        <mvtInputGeo v-model="addressInput" :autoSelect="true"  :isShowLabel="true" :hideButton="true" v-validate="'required|address'"  data-geotype="zipcode" :placeholder="'Address'"  :options="{types:['address']}"/>
                    </mvtTextbox>
                    <mvtTextbox v-else-if="showZipcode">
                        <mvtInputGeo v-model="addressInput" :autoSelect="true"  :isShowLabel="true" :hideButton="true" v-validate="'required|geo'" data-geotype="zipcode" :placeholder="'ZIP Code'" :options="{types:['postal_code']}"/>
                    </mvtTextbox>
                    <mvtTextbox v-else-if="showInputGeo || showInputGeoOptional">
                        <mvtInputGeo v-model="addressInput" :autoSelect="true"  :isShowLabel="true" :hideButton="true" v-validate="showInputGeoOptional ? '' : 'required|geo' " data-geotype="zipcode" :placeholder="'Address or ZIP Code'"/>
                    </mvtTextbox>
                    <mvtTextbox v-if="forceInputPrice">
                        <div class="input">
                            <label>Price</label>
                            <mvtInputNumber
                                name="price"
                                v-model="hotlead.price"
                                maxlength="12"
                                :autocomplete="isACForm ? 'off': 'price'"
                                v-validate="'required|amount'"
                                v-inputtrim
                                :prefix="'$'"
                            />
                        </div>
                    </mvtTextbox>

                    <mvtTextbox class="comments" v-if="showComment">
                        <div class="textarea">
                           <template v-if="options.askAgentBox">
                                <label>{{ molang('search.common.writeMsg') }} <i>{{ molang('search.common.optional') }}</i></label>
                                <textarea name="comment" v-model="hotlead.comment" maxlength="4000"></textarea>
                            </template>
                            <template v-else>
                                <label v-if="messageAgentLeadType">{{ molang('search.common.buySellComments') }}</label>
                                <label v-else>{{ molang('search.common.comments') }}</label>
                                <textarea name="comment" v-model="hotlead.comment" maxlength="4000" v-validate="'required|comment'"></textarea>
                            </template>
                        </div>
                    </mvtTextbox>
                    <div v-if="isACForm" class="text-danger">{{acFormErrorMsg}}</div>
                </div>
            </template>

            <template v-if="showCashOfferCheckbox">
                <mvtCheckbox v-model="isCashOffer" :data="getCashOfferCheckboxData" wrap></mvtCheckbox>
            </template>
            <template v-if="showVeteransOption">
                <mvtCheckbox v-model="isVeteran" :data="getVeteranCheckboxData" wrap></mvtCheckbox>
            </template>

            <template v-if="isCashOfferLead">
                <div class="grid xs-grid-cols-1">
                    <div class="flex middle left"><i class="text-main-l1 icon-check-circle-outside text-brand f3"></i> Sell your home quickly</div>
                    <div class="flex middle left"><i class="text-main-l1 icon-check-circle-outside text-brand f3"></i> Enables you to buy before selling</div>
                    <div class="flex middle left"><i class="text-main-l1 icon-check-circle-outside text-brand f3"></i> No staging or preparation needed</div>
                </div>
            </template>
            <div class="text-error f7" v-if="errorMsg" v-html="errorMsg"></div>
            <div v-if="disclaimer">
                <div ref="disclaimer" class="text-gray f8 lh-normal disclaimer" v-html="disclaimer"></div>
                <mvtCheckbox v-if="showTermCheckbox" v-model="acceptTerms" :data="getTermsCheckboxData" wrap class="corner bg-l1 m-t-3 p-y-2 p-x-2 small"></mvtCheckbox>
            </div>
            <div v-if="assignedAgent" class="agent-contact flex mvt-hotleadform-submit">
                <img v-if="assignedAgent && assignedAgent.photoUrl" :title="assignedAgent.fullName"  alt="Agent Avatar" @click="submit" :src="assignedAgent.photoUrl" style="margin: 3px 0;" />
                <button class="btn large" :class="newDppButtonClass || 'btn primary'" type="submit" :disabled="disableSend">{{ buttonText }}</button>
            </div>
            <button v-else class="large mvt-hotleadform-submit" :class="newDppButtonClass || 'btn primary'" type="submit" :disabled="disableSend">{{ buttonText }}</button>
            <div class="flex middle center f7" v-if="hasOJOAgent || showConcierge">
                <span class="f4 icon-live-connect"></span> Need help?
                <a class="link" :class="{'link-active': formState !== 'submitting'}" @click="openRequestAssistance">Contact your Concierge</a>
            </div>
        </form>
        <div class="disclaimer-tootip" v-if="popDialogBox">
            <div @click="closeTip" class="disclaimer-tootip-overlay"></div>
            <div class="disclaimer-tootip-content">
                <a @click="closeTip" role="button"><i class="icon-times"></i></a>
                <div>
                    {{`Bankrate, LLC will be seeking to connect you with up to four (4) Lending Partners and is a mortgage licensee – #1743443 (<a class="text-dotted" href="https://nam02.safelinks.protection.outlook.com/?url=http%3A%2F%2Fwww.nmlsconsumeraccess.org%2F&data=05%7C01%7Cjlopes%40redventures.com%7C5e5047e0c6d7470cb37e08da68c54799%7C4289d6102cfd46218c9644a1518ddb0a%7C0%7C0%7C637937493777787408%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=AsqCcHC1qxgJe7SUAGWWOlZ0SdYa%2FzsCV%2FeZ7F7Pzh4%3D&reserved=0" target="_blank" ref="nofollow noreferrer">www.nmlsconsumeraccess.org</a>) | <a class="text-dotted" href="https://www.bankrate.com/privacy/" target="_blank" ref="nofollow noreferrer">Privacy Policy</a> | <a class="text-dotted" href="https://www.bankrate.com/terms/" target="_blank" ref="nofollow noreferrer">Terms of Use</a> | <a class="text-dotted" href="https://www.bankrate.com/licenses/" target="_blank" ref="nofollow noreferrer">Licensing Information</a>  | Equal Housing Opportunity. Lending Partners who contact you are not recommended or endorsed by Bankrate, LLC, are not the only providers of mortgage loan services of the kinds they offer and typically will have paid for the opportunity to contact you as a result of this advertisement to further offer their mortgage loan help and services. Standard mobile, message, or data rates may apply.  Qualifying for pre-approval or a loan is not guaranteed, and further information and actions may be required of you.`}}
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./mvt-hotleadform.js"></script>